const actionStrings = (t, labels) => ({
  comment: t("document.actionStrings.comment"),
  // Document
  document_draft_saved: t("document.actionStrings.draftSaved"),
  document_draft_discarded: t("document.actionStrings.draftDiscarded"),
  document_created: t("document.actionStrings.created"),
  document_pended: t("document.actionStrings.edited"),
  document_pended_nochanges: t("document.actionStrings.noChanges"),
  document_overridden: t("document.actionStrings.overridden"),
  document_approval_rejected: t("document.actionStrings.rejected"),
  document_approved: t("document.actionStrings.approved"),
  document_tenant_approved: t("document.actionStrings.approved"),
  document_bulk_approved: t("document.actionStrings.bulkApproved"),
  document_bulk_reviewed: t("document.actionStrings.bulkReviewed"),
  document_bulk_retired: t("document.actionStrings.bulkRetired"),
  document_made_active: t("document.actionStrings.activated"),
  document_activated: t("document.actionStrings.activated"),
  document_scheduled_for_activation: t(
    "document.actionStrings.scheduledForActivation"
  ),
  document_effective_date_scheduled: t(
    "document.actionStrings.effectiveDateScheduleChanged"
  ),
  document_origination_date_changed: t(
    "document.actionStrings.originationDateChanged"
  ),
  document_retired: t("document.actionStrings.retired"),
  document_deleted: t("document.actionStrings.deleted"),
  document_imported: t("document.actionStrings.initiallyImported"),
  document_accepted: t("document.actionStrings.accepted"),
  document_rejected: t("document.actionStrings.documentRejected"),
  document_find_and_replace: t("document.actionStrings.findAndReplace"),
  document_tags_changed: t("document.actionStrings.referencesChanged", {
    referencesLabelPlural: labels.referencesLabelPlural
  }),
  document_recurring_acknowledgment_assigned: t(
    "document.actionStrings.recurringAcknowledgmentAssigned"
  ),
  document_recurring_acknowledgment_triggered: t(
    "document.actionStrings.recurringAcknowledgmentTriggered"
  ),
  document_recurring_acknowledgments_removed: t(
    "document.actionStrings.recurringAcknowledgmentRemoved"
  ),
  document_category_changed: t("document.actionStrings.categoryChanged"),
  document_approval_flow_auto_update: t(
    "document.actionStrings.approvalFlowAutoUpdate"
  ),
  document_responsibilities_transferred: t(
    "document.actionStrings.responsibilitiesTransferred"
  ),
  document_category_name_changed: t(
    "document.actionStrings.categoryNameChanged"
  ),
  document_regulation_removed: t("document.actionStrings.regulationRemoved"),
  document_regulation_bulk_removed: t(
    "document.actionStrings.regulationBulkRemoved"
  ),
  document_cloned: t("document.actionStrings.cloned"),
  document_import_uploaded: t("document.actionStrings.uploaded"),
  document_import_cleaned: t("document.actionStrings.cleaned"),
  document_import_extracted: t("document.actionStrings.extracted"),
  document_import_confirmed: t("document.actionStrings.confirmed"),
  document_import_clean_override: t("document.actionStrings.cleaningOverride"),
  document_import_extract_override: t(
    "document.actionStrings.extractionOverride"
  ),
  document_import_sent_back: t("document.actionStrings.sentBack"),
  document_import_sent_back_cleaning: t(
    "document.actionStrings.sentBackCleaned"
  ),
  document_import_sent_back_extracting: t(
    "document.actionStrings.sentBackExtracting"
  ),
  document_import_reimported: t("document.actionStrings.reImported"),
  document_import_internal_hold_added: t(
    "document.actionStrings.internalHoldAdded"
  ),
  document_import_external_hold_added: t(
    "document.actionStrings.clarificationRequired"
  ),
  document_import_internal_hold_removed: t(
    "document.actionStrings.holdRemoved"
  ),
  document_import_external_hold_removed: t(
    "document.actionStrings.holdRemoved"
  ),
  document_import_deleted: t("document.actionStrings.deleted"),
  document_import_undeleted: t("document.actionStrings.unDeleted"),
  document_import_converted_source_file_changed: t(
    "document.actionStrings.convertedSourceFileChanged"
  ),
  document_import_overridden: t("document.actionStrings.importOverridden"),
  system_document_template_document_retired: t(
    "document.actionStrings.systemDocumentTemplateRetired",
    labels
  ),
  system_document_template_document_published: t(
    "document.actionStrings.systemDocumentTemplatePublished",
    labels
  )
});

export default actionStrings;
