// @flow
import graphql from "babel-plugin-relay/macro";
import { withRouter } from "found";
import { Box } from "grid-styled";
import queryString from "query-string";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";

import { ContentContainerFlex } from "../../pages";
import OneSourceSection from "../onesource";
import { OnesourceDocumentsProvider } from "../onesource/OneSourceContext";
import CommentPanel from "../view_policy/comment_form";
import History from "../view_policy/history";
import { EditStatusBar } from "../view_policy/StatusBar";
import TableOfContents from "../view_policy/table_of_contents";

import Advanced from "./components/Advanced";
import ApprovalSimulation from "./components/ApprovalSimulation";
import Attachments from "./components/Attachments";
import ExpirationDefaultWarning from "./components/DefaultExpirationWarning";
import Editor from "./components/Editor";
import EffectiveDate from "./components/EffectiveDate";
import ExistingDraftModal from "./components/ExistingDraftModal";
import NavBar from "./components/Navbar";
import PolicyProperties from "./components/PolicyProperties";
import Preview from "./components/Preview";
import SideBar from "./components/SideBar";
import {
  OverrideDocumentModal,
  StartApprovalsModal
} from "./components/SubmitModals";
import usePolicyEditor, { useBeforeUnload } from "./hooks";
import { CREATE_TYPE, isCreatePage, isEditPage, isOverridePage } from "./utils";

import { isPending, isScheduled } from "pstat-anywhere/utils/document";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { TERTIARY_NAVBAR_HEIGHT } from "pstat-anywhere/components/partials/tertiary_nav";
import { EpAssociationViewMenu } from "pstat-anywhere/components/integrations/verge/EpAssociationViewMenu";
import DiscardDraftModal from "pstat-anywhere/components/document_draft/DiscardDraftModal";
import { VergeStandardsPanel } from "pstat-anywhere/components/document_control/view_policy/VergeStandards";

export type PolicyEditorData = {
  loading: boolean,
  error: {
    message: string
  },
  tenant: Object,
  accessibleTenant: Object
};

type Props = {
  type?: CREATE_TYPE | EDIT_TYPE | OVERRIDE_TYPE,
  ...PolicyEditorData
};

function PolicyEditor({
  type = CREATE_TYPE,
  error,
  document,
  documentSave,
  editForm,
  tagForm,
  saveForm,
  scheduledEffectiveDateForm,
  systemDocumentTemplateForm,
  preimportRevisionForm,
  policyActions,
  route,
  alreadyChanged,
  router,
  tenant,
  viewer,
  rtlSupport,
  newCkeditor,
  ckeditor5,
  labels,
  createFromWord,
  enabledAutoSaveTimeout = false,
  canLocalize = null
}: Props) {
  const { t } = useTranslation();
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [submitModalLoading, setSubmitModalLoading] = useState(false);
  const [canMarkApproval, setCanMarkApproval] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedFlowLoading, setSelectedFlowLoading] = useState(false);
  const [editor, setEditor] = useState(null);
  const [discardDraftOpen, setDiscardDraftOpen] = useState(false);

  const [tocExpanded, setTocExpanded] = useState(false);
  const [showEpAssociationViewMenu, setShowEpAssociationViewMenu] = useState(
    false
  );
  const [epContent, setEpContent] = useState(null);
  const [convertingToTemplate, setConvertingToTemplate] = useState(false);

  const basedOnSystemDocumentTemplate = !!document?.systemDocumentTemplate;

  const isOverride = isOverridePage(type);
  const isEdit = isEditPage(type);
  const isCreate = isCreatePage(type);

  const shouldShowExistingDraftModal =
    !isOverride && saveForm.hasSavedCopy && !saveForm.continueDraft;
  const [showExistingDraftModal, setShowExistingDraftModal] = useState(
    shouldShowExistingDraftModal
  );

  const closeEpAssociationMenu = () => {
    setShowEpAssociationViewMenu(false);
  };

  const openEpAssociationMenu = content => {
    setShowEpAssociationViewMenu(true);
    setEpContent(content);
  };

  const parsed = queryString.parse(window.location.search);
  const isClone = parsed["clone"] === "true";
  const blankFlow = parsed["no_flow"] === "1";
  const blankLifespan = parsed["no_lifespan"] === "1";

  const isTemplate =
    document?.isTemplate || documentSave?.savedCopy?.isTemplate;
  const saveLocations = isTemplate || (canLocalize && convertingToTemplate);

  const {
    noChangesMade,
    autoSave,
    changesSaved,
    saving,
    formError,
    policyForm,
    errorFound,
    activeLifespanSelected,
    effectiveDateSelectedOption,
    expandAdvanced,
    commentRequired,
    showPreview,
    effectiveDate,
    effectiveDateTimedelta,
    effectiveDateAlgorithm,
    toggleAdvancedSection,
    expirationWarning,
    newSavedDraftUrl,
    handleNameChange,
    handleNameClear,
    handleAuthorChange,
    handleAuthorClear,
    handleAttachmentChange,
    handleCategoryChange,
    handleCategoryClear,
    handleFlowTemplateChange,
    handleFlowTemplateClear,
    handleBlocChange,
    handleBlocClear,
    handleActiveLifespanChange,
    handleActiveLifespanDateCheck,
    handleInconsistentExpirationDateUpdate,
    handleExpirationDateChange,
    handleReferencesChange,
    handleReferencesClear,
    handleHtmlChange,
    handleDataReady,
    handleEffectiveDateTimedeltaChange,
    handleEffectiveDateDatetimeChange,
    handleHidePDFWatermarkChange,
    handleRestrictedChange,
    handleRegulationsChange,
    handleRegulationsClear,
    handleLocationsChange,
    handleLocationsClear,
    handleApprovalDateChange,
    handleOriginationDateChange,
    handleLegacyEffectiveDateChange,
    handleApproverChange,
    handleApproverClear,
    handleApprovedDateChange,
    handleApprovedDateClear,
    handleRevisionDatesChange,
    handleRevisionDatesClear,
    setAttachments,
    updateAttachments,
    toggleReviewDateOption,
    toggleEffectiveDateOption,
    startApprovalFlow,
    overrideDocument,
    savePolicy,
    previewPolicy,
    toggleAutoSave,
    setShowPreview,
    setChangesMade,
    commentDocumentSave
  } = usePolicyEditor({
    type,
    document,
    documentSave,
    editForm,
    tagForm,
    scheduledEffectiveDateForm,
    systemDocumentTemplateForm,
    preimportRevisionForm,
    setSubmitModalIsOpen,
    setSubmitModalLoading,
    alreadyChanged,
    router,
    createFromWord,
    enabledAutoSaveTimeout,
    isClone,
    blankFlow,
    blankLifespan,
    saveLocations
  });

  const temporarilyDisableBeforeUnload = useBeforeUnload(event => {
    const changesMadeOnOverridePage = isOverride && !noChangesMade;
    const changesNotSaved = !noChangesMade && !changesSaved;
    if (changesMadeOnOverridePage || changesNotSaved || saving) {
      event.preventDefault();
      event.returnValue = "";
    }
  });
  const shouldShowMarkMyApprovalCheckbox = () => {
    if (!selectedFlow) {
      return false;
    }
    const firstStep = selectedFlow.steps[0];
    if (!firstStep) {
      return false;
    }
    const firstStepApproverPks = firstStep.approvers.map(
      approver => approver.pk
    );
    if (firstStepApproverPks.indexOf(viewer.pk) > -1) {
      return true;
    }
    if (!firstStep.includeDocumentOwner) {
      return false;
    }
    return viewer.pk === policyForm.author;
  };

  const handleStartApprovals = () => {
    const canMarkApproval = shouldShowMarkMyApprovalCheckbox();
    setCanMarkApproval(canMarkApproval);
    setSubmitModalIsOpen(true);
  };
  const handleOverrideDocument = () => {
    setCanMarkApproval(false);
    setSubmitModalIsOpen(true);
  };

  const toggleDiscardDraftModal = () => {
    setDiscardDraftOpen(!discardDraftOpen);
  };

  if (error) {
    return <p>{error.message}</p>;
  }
  const documentNotYetEffective =
    isOverride && (isPending(document.status) || isScheduled(document.status));
  const showEffectiveDatePanel = !isOverride || documentNotYetEffective;

  return (
    <Fragment>
      <NavBar
        noChangesMade={noChangesMade}
        changesSaved={changesSaved}
        autosave={autoSave}
        saving={saving}
        error={errorFound}
        openSubmitModal={
          isOverridePage(type) ? handleOverrideDocument : handleStartApprovals
        }
        selectedFlowLoading={selectedFlowLoading}
        savePolicy={savePolicy}
        previewPolicy={previewPolicy}
        toggleAutosave={toggleAutoSave}
        isOverridePage={isOverride}
        policyActions={policyActions}
        route={route}
        document={document}
        type={type}
        deleteDraftToggle={toggleDiscardDraftModal}
        newSavedDraftUrl={newSavedDraftUrl}
        isClone={isClone}
      />
      <Preview
        type={type}
        isOpen={showPreview}
        documentSave={documentSave}
        document={document}
        policyForm={policyForm}
        effectiveDate={effectiveDate}
        effectiveDateTimedelta={effectiveDateTimedelta}
        effectiveDateAlgorithm={effectiveDateAlgorithm}
        onClose={() => setShowPreview(false)}
        documentSettings={tenant.documentSettings}
      />
      {showExistingDraftModal && (
        <ExistingDraftModal
          isOpen={showExistingDraftModal}
          onClose={() => setShowExistingDraftModal(false)}
          author={documentSave.savedCopy.author}
          documentSavePk={documentSave.pk}
        />
      )}
      {isOverride ? (
        <OverrideDocumentModal
          id="override_modal"
          isOpen={submitModalIsOpen}
          commentRequired={commentRequired}
          onClose={() => setSubmitModalIsOpen(false)}
          onSubmit={overrideDocument}
          loading={submitModalLoading}
          error={formError.action_comment}
        />
      ) : (
        <StartApprovalsModal
          id="start_approvals_modal"
          isOpen={submitModalIsOpen}
          commentRequired={commentRequired}
          onClose={() => setSubmitModalIsOpen(false)}
          onSubmit={startApprovalFlow}
          loading={submitModalLoading}
          canMarkApproval={canMarkApproval}
          error={formError.action_comment}
          showTemplatePlaceholderReminder={basedOnSystemDocumentTemplate}
        />
      )}
      <TocContainer>
        <TableOfContents
          hasAttachment={policyForm.attachments.length > 0}
          html={policyForm.html}
          expanded={tocExpanded}
          activeItem={() => {}}
          onToggle={() => setTocExpanded(!tocExpanded)}
          onClose={() => setTocExpanded(false)}
        />
        <ContentContainerFlex wrap>
          <SideBar
            id="sidebar"
            top={TERTIARY_NAVBAR_HEIGHT} // for some reason the sticky navbar is not taking up space on the page, so need to set an offset so it doesn't overlap the side bar
            width={[1, 1, 1 / 6]}
            p={[0, 0, 3]}
            titleName={t(`documentControl.${type}.sideBar.title`, {
              documentLabel: labels.documentLabel
            })}
            isOverride={isOverride}
          />
          <Box
            id="edit_container"
            width={[1, 1, 5 / 6]}
            p={[0, 0, 3]}
            role="main"
          >
            {expirationWarning && (
              <ExpirationDefaultWarning expirationWarning={expirationWarning} />
            )}
            {document && (
              <EditStatusBar
                document={{ ...document, category: editForm.category }} // need category name for google-analytics event
                viewer={viewer}
                isOverride={isOverride}
                policyActions={policyActions}
              />
            )}
            <PolicyProperties
              document={document}
              type={type}
              policyForm={policyForm}
              onNameChange={handleNameChange}
              onNameClear={handleNameClear}
              onAuthorChange={handleAuthorChange}
              onAuthorClear={handleAuthorClear}
              onCategoryChange={handleCategoryChange}
              onCategoryClear={handleCategoryClear}
              onFlowTemplateChange={handleFlowTemplateChange}
              onFlowTemplateClear={handleFlowTemplateClear}
              onBlocChange={handleBlocChange}
              onBlocClear={handleBlocClear}
              handleActiveLifespanDateCheck={handleActiveLifespanDateCheck}
              onActiveLifespanChange={handleActiveLifespanChange}
              handleInconsistentExpirationDateUpdate={
                handleInconsistentExpirationDateUpdate
              }
              onExpirationDateChange={handleExpirationDateChange}
              onReferencesChange={handleReferencesChange}
              onReferencesClear={handleReferencesClear}
              onApprovalDateChange={handleApprovalDateChange}
              onOriginationDateChange={handleOriginationDateChange}
              onEffectiveDateDatetimeChange={handleEffectiveDateDatetimeChange}
              onLegacyEffectiveDateChange={handleLegacyEffectiveDateChange}
              onRegulationsChange={handleRegulationsChange}
              onRegulationsClear={handleRegulationsClear}
              onLocationsChange={handleLocationsChange}
              onLocationsClear={handleLocationsClear}
              onRevisionDatesChange={handleRevisionDatesChange}
              onRevisionDatesClear={handleRevisionDatesClear}
              errors={formError}
              toggleRadioButtons={toggleReviewDateOption}
              activeLifespanSelected={activeLifespanSelected}
              editForm={editForm}
              tagForm={tagForm}
              selectedFlow={selectedFlow}
              preimportRevisionForm={preimportRevisionForm}
              onSelectedFlowChange={setSelectedFlow}
              selectedFlowLoading={selectedFlowLoading}
              onSelectedFlowLoadingChange={setSelectedFlowLoading}
              scheduledEffectiveDateEnabled={
                tenant.hasScheduledEffectiveDateEnabled
              }
              basedOnSystemDocumentTemplate={basedOnSystemDocumentTemplate}
              canLocalize={canLocalize}
              isTemplate={isTemplate}
              convertingToTemplate={convertingToTemplate}
              setConvertingToTemplate={setConvertingToTemplate}
            />
            <OneSourceSection onChangesMade={setChangesMade} />
            <Editor
              policyForm={policyForm}
              onChange={handleHtmlChange}
              onDataReady={handleDataReady}
              rtlSupport={rtlSupport}
              newCkeditor={newCkeditor}
              ckeditor5={ckeditor5}
              documentSave={documentSave}
              document={{ ...document, category: editForm.category }} // need category name for google-analytics event
              setAttachments={setAttachments}
              temporarilyDisableBeforeUnload={temporarilyDisableBeforeUnload}
              enableEditWithWord={tenant.enableEditWithWord}
              setEditor={setEditor}
              isOverridePage={isOverride}
              editorStyles={tenant?.documentSettings?.viewStyles}
              isTemplate={isTemplate || convertingToTemplate}
              isBasedOnTemplate={basedOnSystemDocumentTemplate}
            />
            <Attachments
              documentSave={documentSave}
              document={document}
              attachments={policyForm.attachments}
              setAttachments={setAttachments}
              updateAttachments={updateAttachments}
              handleAttachmentChange={handleAttachmentChange}
              isOverridePage={isOverride}
              editor={editor}
              ckeditor5={ckeditor5}
            />
            {tenant.vergeIntegrationEnabled && type !== CREATE_TYPE && (
              <Box width={1}>
                <VergeStandardsPanel
                  openEpContentPanel={openEpAssociationMenu}
                  associations={document?.epAssociationDocuments}
                />
              </Box>
            )}
            {showEffectiveDatePanel && (
              <EffectiveDate
                policyForm={policyForm}
                errors={formError}
                onEffectiveDateDatetimeChange={
                  handleEffectiveDateDatetimeChange
                }
                onEffectiveDateTimedeltaChange={
                  handleEffectiveDateTimedeltaChange
                }
                toggleRadioButtons={toggleEffectiveDateOption}
                effectiveDateSelectedOption={effectiveDateSelectedOption}
                isScheduled={isOverride && isScheduled(document.status)}
              />
            )}
            {isOverride && (
              <ApprovalSimulation
                policyForm={policyForm}
                onApproverChange={handleApproverChange}
                onApproverClear={handleApproverClear}
                onApprovedDateChange={handleApprovedDateChange}
                onApprovedDateClear={handleApprovedDateClear}
                errors={formError}
              />
            )}
            {(isEdit || isCreate) && (
              <DiscardDraftModal
                isOpen={discardDraftOpen}
                onClose={toggleDiscardDraftModal}
                documentSavePk={documentSave.pk}
              />
            )}
            <Advanced
              policyForm={policyForm}
              onHidePDFWatermarkChange={handleHidePDFWatermarkChange}
              onRestrictedChange={handleRestrictedChange}
              expandAdvanced={expandAdvanced}
              toggleAdvancedSection={toggleAdvancedSection}
            />
            {document && (
              <Fragment>
                <History
                  actions={document.actions}
                  documentPk={document.pk}
                  viewer={viewer}
                />
                {(!isCreate || (documentSave && documentSave.savedCopy)) && (
                  <CommentPanel
                    document={document}
                    documentSave={commentDocumentSave || documentSave}
                    isEditPage={isEditPage(type) || isOverride}
                    type={type}
                  />
                )}
              </Fragment>
            )}
          </Box>
        </ContentContainerFlex>
        {showEpAssociationViewMenu && (
          <EpAssociationViewMenu
            onClose={closeEpAssociationMenu}
            content={epContent}
          />
        )}
      </TocContainer>
    </Fragment>
  );
}

const PolicyEditorFragementContainer = createFragmentContainer(
  PolicyEditor,
  graphql`
    fragment PolicyEditor_document on DocumentType
    @argumentDefinitions(isEditPage: { type: "Boolean", defaultValue: false }) {
      id
      pk
      name
      status
      actions(isEditPage: $isEditPage) {
        id
        pk
        actionType
        actionDate
        comment
        canEdit
        isExternal
        user {
          id
          userDisplay(userDisplayType: FORWARD)
        }
      }
      epAssociationDocuments {
        pk
      }
      systemDocumentTemplate {
        pk
      }
      ...commentForm_document
      isTemplate
    }
    fragment PolicyEditor_documentSave on DocumentSaveType {
      pk
      savedCopy {
        pk
        author {
          pk
          firstName
          lastName
          email
        }
        isTemplate
      }
    }
  `
);

const PolicyEditorWithStateProviders = props => {
  const { document, editForm } = props;
  let defaultOnesourceDocuments = [];
  if (document) {
    defaultOnesourceDocuments = editForm?.onesourceDocuments || [];
  }
  return (
    <OnesourceDocumentsProvider
      defaultOnesourceDocuments={defaultOnesourceDocuments}
    >
      <PolicyEditorFragementContainer {...props} />
    </OnesourceDocumentsProvider>
  );
};

export default withLabelContext(
  withTenantContext(withViewer(withRouter(PolicyEditorWithStateProviders)))
);

const TocContainer = styled.div`
  position: relative;
`;
